// import { init, track, setUserId } from '@amplitude/marketing-analytics-browser';
import { init, track } from '@amplitude/marketing-analytics-browser';
import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import { PageKeys } from '@analytics/PageKeys';
// import { useSession as useNextSession } from 'next-auth/react';
import { convertObjectKeys } from '@lib/convertObjectKeys';
import { isNonEmptyString } from '@lib/isNonEmptyString';
// import { isNonEmptyString } from '@utils/isNonEmptyString';
import { snakeCase } from 'lodash';
// import { useCallback, useEffect, useRef } from 'react';

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development';
let amplitudeInited = false;

export const initAmplitude = () => {
  if (!isNonEmptyString(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY)) {
    console.error('Cannot init amplitude: missing NEXT_PUBLIC_AMPLITUDE_API_KEY');
    return;
  }
  if (amplitudeInited) {
    return;
  }
  init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
    trackingOptions: {
      city: true,
      country: true,
      carrier: true,
      device_manufacturer: true,
      device_model: true,
      dma: true,
      ip_address: true,
      language: true,
      os_name: true,
      os_version: true,
      platform: true,
      region: true,
      version_name: true
    }
  });
  amplitudeInited = true;
};

export function trackEvent(event: AnalyticsEvents, properties?: Record<string, any>) {
  const hasWindow = typeof window !== 'undefined';
  const defaultProperties = {
    location: {
      pathname: hasWindow ? window.location?.pathname : '',
      hostname: hasWindow ? window.location?.hostname : ''
    }
  };
  const formattedProperties = convertObjectKeys({ ...defaultProperties, ...properties }, snakeCase);
  const formattedEvent = snakeCase(event);
  if (environment !== 'production') {
    console.log('Amplitude trackEvent skipped - not production environment', {
      event: formattedEvent,
      properties: formattedProperties
    });
  } else {
    track(formattedEvent, formattedProperties);
  }
}

// const useTrackUser = () => {
// const { data: session } = useNextSession();
// useEffect(() => {
//   if (session != null && session.user != null) {
//     setUserId(session.user.email);
//   }
// }, [session]);
// };

export function useTrackEvent() {
  // useTrackUser();
  // return useCallback(trackEvent, []);
}

export function useTrackEventOnMount(_event: AnalyticsEvents, _properties?: Record<string, any>, _key?: string) {
  // useTrackUser();
  // const trackerRef = useRef(false);
  // const prevKey = useRef(key);
  // const trackEventHook = useTrackEvent();
  // useEffect(() => {
  //   if (!window || !document || !isNonEmptyString(event)) return;
  //   if (prevKey.current !== key) {
  //     prevKey.current = key;
  //     trackerRef.current = false;
  //   }
  //   if (trackerRef.current === true) return;
  //   trackerRef.current = true;
  //   trackEventHook(event, properties);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [key]);
}

export function useTrackPageView(_pageKey: PageKeys, _properties?: Record<string, any>, _key?: string) {
  // useTrackEventOnMount(
  //   AnalyticsEvents.pageView,
  //   {
  //     page_key: pageKey,
  //     metadata: properties
  //   },
  //   key
  // );
}
