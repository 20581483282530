import * as React from 'react';
import { useSession } from '..';

export const useHasAccess = (feature: keyof typeof subscriptionPlan.features) => {
  const { subscriptionPlan } = useSession();

  return React.useMemo(() => {
    return subscriptionPlan.features[feature];
  }, [feature, subscriptionPlan.features]);
};
