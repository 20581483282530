import { css } from 'styled-components';
import theme from '../../theme';
import getPadding from '../utils/getPadding';
import { Size } from '../types/types';

const accent = (size: Size) => css`
  :hover {
    background-color: #38a5f4;
  }

  :focus,
  :active {
    background-color: #38a5f4;
    border: 2px solid ${theme.colors.stroke.primary};

    ${(() => {
      const { px, py } = getPadding(size);
      return css`
        padding: ${theme.space[py] - 2}px ${theme.space[px] - 2}px;
      `;
    })()}
  }

  :disabled {
    border: 0;
    background-color: #0c8ce9;
    opacity: 0.5;
  }
`;

export default accent;
