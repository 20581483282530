import { css } from 'styled-components';
import theme from '../../theme';
import getPadding from '../utils/getPadding';
import { transparentize } from 'polished';
import { Size } from '../types/types';

const primary = (size: Size) => css`
  :hover {
    background-color: ${transparentize(0.2, theme.colors.background.contrast)};
  }

  :focus,
  :active {
    background-color: ${transparentize(0.2, theme.colors.background.contrast)};
    border: 2px solid ${theme.colors.stroke.primary};

    ${(() => {
      const { px, py } = getPadding(size);
      return css`
        padding: ${theme.space[py] - 2}px ${theme.space[px] - 2}px;
      `;
    })()}
  }

  :disabled {
    border: 0;
    opacity: 1;
    background-color: ${transparentize(0.5, theme.colors.background.contrast)};
  }
`;

export default primary;
