import { captureException } from '@sentry/nextjs';
import { useNotify } from './useNotify';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useCreate, useVisibleItems } from '@store/hooks';
import { addFeedItem } from '@/_data/feed/useGetFeed';

type UpscaleArgs = {
  assetId?: string | null;
};

const upscale = async ({ assetId }: UpscaleArgs) => {
  if (!assetId) throw new Error('assetId is required');

  const response = await fetch(`/api/image/upscale`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({ assetId })
  });

  return response.json();
};

type Options = UseMutationOptions<unknown, Error>;
type Args = {
  options?: Options;
  onSuccess?: (data: any) => void;
} & UpscaleArgs;

export const useUpscale = ({ assetId, options, onSuccess }: Args) => {
  const notify = useNotify();
  const { incrementVisibleItems } = useVisibleItems();
  const { setIsCreating } = useCreate();

  return useMutation({
    ...options,
    mutationFn: () =>
      upscale({
        assetId
      }),
    mutationKey: ['upscale', assetId],
    onSuccess: data => {
      setIsCreating(false);
      onSuccess?.(data);
      incrementVisibleItems();
      addFeedItem(data);
    },
    onError: (e: unknown) => {
      setIsCreating(false);
      notify('Could not upscale image', 'error');
      captureException(e);
    }
  });
};
