export enum AnalyticsEvents {
  'albumCreationFailed' = 'album_creation_failed',
  'albumCreationFinished' = 'album_creation_finished',
  'albumCreationProgress' = 'album_creation_progress',
  'albumCreationStarted' = 'album_creation_started',
  'albumView' = 'album_view',
  'albumImageModalView' = 'album_image_modal_view',
  'browsePacksView' = 'browse_packs_view',
  'checkoutComplete' = 'checkout_complete',
  'checkoutStart' = 'checkout_start',
  'claimedFreePack' = 'claimed_free_pack',
  'clickPublishThemeButton' = 'click_publish_theme_button',
  'comment' = 'comment',
  'createPrompt' = 'create_prompt',
  'createPromptDone' = 'create_prompt_done',
  'createTheme' = 'create_theme',
  'createUser' = 'create_user',
  'createView' = 'create_view',
  'dashboardView' = 'dashboard_view',
  'deletePrompt' = 'delete_prompt',
  'exploreView' = 'explore_view',
  'faqView' = 'faq_view',
  'feedView' = 'feed_view',
  'imageUpload' = 'image_upload',
  'instagramConnect' = 'instagram_connect',
  'likePrompt' = 'like_prompt',
  'modelView' = 'model_view',
  'marketingView' = 'marketing_view',
  'packPreviewView' = 'pack_preview_view',
  'packsShopView' = 'packs_shop_view',
  'packView' = 'pack_view',
  'pageView' = 'page_view',
  'party' = 'party',
  'photoDownload' = 'photo_download',
  'pricingPageButtonClicked' = 'pricing_page_button_clicked',
  'pricingPageViewed' = 'pricing_page_viewed',
  'promptArchived' = 'prompt_archived',
  'promptPublished' = 'prompt_published',
  'promptView' = 'prompt_view',
  'profileSettingsView' = 'profile_settings_view',
  'profileView' = 'profile_view',
  'reviewsView' = 'reviews_view',
  'rateAlbum' = 'rate_album',
  'referView' = 'refer_view',
  'rewrite_subject' = 'rewrite_subject',
  'search' = 'search',
  'serviceChanged' = 'service_changed',
  'setCanvas' = 'set_canvas',
  'setOutput' = 'set_output',
  'SEOPageView' = 'seo_page_view',
  'shuffleButtonClicked' = 'shuffle_button_clicked',
  'signIn' = 'sign_in',
  'sidebarPageChanged' = 'sidebar_page_changed',
  'stylesTabChanged' = 'styles_tab_changed',
  'subscriptionCancelled' = 'subscription_cancelled',
  'subscriptionCreated' = 'subscription_created',
  'themeUsed' = 'theme_used',
  'themeView' = 'theme_view',
  'trainGuidelinesViewed' = 'train_guidelines_viewed',
  'trainResponse' = 'train_response',
  'trainStep' = 'train_step',
  'trainThemeSelected' = 'train_theme_selected',
  'trainingFinished' = 'training_finished',
  'unauthorized' = 'unauthorized',
  'unlikePrompt' = 'unlike_prompt',
  'upsellModalButtonClicked' = 'upsell_modal_button_clicked',
  'upsellModalViewed' = 'upsell_modal_viewed',
  'weightsUploadFinished' = 'weights_upload_finished',
  'weightsUploadStarted' = 'weights_upload_started',
  'claimPacks' = 'claim_packs'
}
