import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  searchPrompts,
  fetchPrompt,
  searchLikedPrompts,
  searchCreatedPrompts,
  likePrompt,
  unLikePrompt,
  deletePrompt,
  editPrompt,
  deleteAsset
} from './actions';
import { PromptState } from './reducer';
import { PromptResult } from './types';

export const extraReducers = (builder: ActionReducerMapBuilder<PromptState>) => {
  builder.addCase(searchPrompts.pending, (state, action) => {
    if (typeof action.meta.arg.query !== 'undefined') {
      state.isLoading[action.meta.arg.query + action.meta.arg.provider + action.meta.arg.order] = true;
    }
  });

  builder.addCase(searchPrompts.fulfilled, (state, action) => {
    const cacheIndividually = (prompt: PromptResult) => {
      state.promptMap[prompt.id] = prompt;
    };

    if (action.payload) {
      const keys = Object.keys(action.payload);
      keys.forEach(key => {
        state.isLoading[key] = false;
        const oldResults = state.promptResults[key] || [];
        let newResults = action.payload?.[key].prompts || [];
        newResults.forEach(cacheIndividually);
        const numResults = newResults.length;
        newResults = newResults.filter(newResult => !oldResults.find(oldResult => oldResult.id === newResult.id));
        state.promptResults[key] = [...oldResults, ...newResults];
        if (numResults === 0) {
          state.noMoreResults[key] = true;
        }
        const nextPage = action.payload?.[key].nextPage;
        state.nextPage[key] = nextPage;
      });
    }
  });

  builder.addCase(searchPrompts.rejected, (state, action) => {
    if (typeof action.meta.arg.query !== 'undefined') {
      state.isLoading[action.meta.arg.query + action.meta.arg.provider] = true;
    }
  });

  builder.addCase(fetchPrompt.pending, (state, action) => {
    if (action.meta.arg) {
      state.isLoading[action.meta.arg] = true;
    }
  });

  builder.addCase(fetchPrompt.fulfilled, (state, action) => {
    if (action.payload) {
      state.isLoading[action.payload.id] = true;
      state.promptMap[action.payload.id] = action.payload;
    }
  });

  builder.addCase(fetchPrompt.rejected, (state, action) => {
    if (action.meta.arg) {
      state.isLoading[action.meta.arg] = false;
    }
  });

  builder.addCase(searchLikedPrompts.pending, (state, action) => {
    if (typeof action.meta.arg.userId !== 'undefined') {
      state.isLoading[action.meta.arg.userId + 'likes' + action.meta.arg.provider] = true;
    }
  });

  builder.addCase(searchLikedPrompts.fulfilled, (state, action) => {
    const cacheIndividually = (prompt: PromptResult) => {
      state.promptMap[prompt.id] = prompt;
    };

    if (action.payload) {
      const keys = Object.keys(action.payload);
      keys.forEach(key => {
        state.isLoading[key] = false;
        const oldResults = state.promptResults[key] || [];
        let newResults = action.payload?.[key].prompts || [];
        newResults.forEach(cacheIndividually);
        const numResults = newResults.length;
        newResults = newResults.filter(newResult => !oldResults.find(oldResult => oldResult.id === newResult.id));
        state.promptResults[key] = [...oldResults, ...newResults];
        if (numResults === 0) {
          state.noMoreResults[key] = true;
        }
        const nextPage = action.payload?.[key].nextPage;
        state.nextPage[key] = nextPage;
      });
    }
  });

  builder.addCase(searchLikedPrompts.rejected, (state, action) => {
    if (typeof action.meta.arg.userId !== 'undefined') {
      state.isLoading[action.meta.arg.userId + 'likes' + action.meta.arg.provider] = true;
    }
  });

  builder.addCase(likePrompt.pending, (state, action) => {
    const promptId = action.meta.arg;
    const liked = true;
    if (state.promptMap[promptId]) {
      state.promptMap[promptId].liked = liked;
    }
    Object.keys(state.promptResults).forEach(key => {
      const prompt = state.promptResults[key].find(prompt => prompt.id === promptId);
      if (prompt) {
        prompt.liked = liked;
        prompt.likeCount++;
      }
    });
  });

  builder.addCase(unLikePrompt.pending, (state, action) => {
    const promptId = action.meta.arg;
    const liked = false;
    if (state.promptMap[promptId]) {
      state.promptMap[promptId].liked = liked;
    }
    Object.keys(state.promptResults).forEach(key => {
      const prompt = state.promptResults[key].find(prompt => prompt.id === promptId);
      if (prompt) {
        prompt.liked = liked;
        prompt.likeCount--;
      }
    });
  });

  builder.addCase(searchCreatedPrompts.pending, (state, action) => {
    if (typeof action.meta.arg.userId !== 'undefined') {
      state.isLoading[action.meta.arg.userId + 'creations' + action.meta.arg.filter] = true;
    }
  });

  builder.addCase(searchCreatedPrompts.fulfilled, (state, action) => {
    const cacheIndividually = (prompt: PromptResult) => {
      state.promptMap[prompt.id] = prompt;
    };

    if (action.payload) {
      const keys = Object.keys(action.payload);
      keys.forEach(key => {
        state.isLoading[key] = false;
        const oldResults = state.promptResults[key] || [];
        let newResults = action.payload?.[key].prompts || [];
        newResults.forEach(cacheIndividually);
        const numResults = newResults.length;
        newResults = newResults.filter(newResult => !oldResults.find(oldResult => oldResult.id === newResult.id));
        state.promptResults[key] = [...oldResults, ...newResults];
        if (numResults === 0) {
          state.noMoreResults[key] = true;
        }
        const nextPage = action.payload?.[key].nextPage;
        state.nextPage[key] = nextPage;
      });
    }
  });

  builder.addCase(searchCreatedPrompts.rejected, (state, action) => {
    if (typeof action.meta.arg.userId !== 'undefined') {
      state.isLoading[action.meta.arg.userId + 'creations' + action.meta.arg.filter] = true;
    }
  });
  builder.addCase(deletePrompt.pending, (state, action) => {
    const promptId = action?.meta?.arg;
    if (promptId) {
      delete state.promptMap[promptId];
      Object.keys(state.promptResults).forEach(key => {
        state.promptResults[key] = state.promptResults[key].filter(prompt => prompt.id !== promptId);
      });
    }
  });
  builder.addCase(editPrompt.pending, (state, action) => {
    const promptId = action?.meta?.arg?.id;
    const isArchived = action?.meta?.arg?.isArchived;
    const isPrivate = action?.meta?.arg?.isPrivate;
    if (promptId && isArchived) {
      state.promptMap[promptId].isArchived = isArchived;
      Object.keys(state.promptResults).forEach(key => {
        const prompt = state.promptResults[key].find(prompt => prompt.id === promptId);
        if (prompt) {
          prompt.isArchived = isArchived;
        }
      });
    } else if (promptId && isPrivate) {
      Object.keys(state.promptResults).forEach(key => {
        const prompt = state.promptResults[key].find(prompt => prompt.id === promptId);
        if (prompt) {
          prompt.isPrivate = isPrivate;
        }
      });
    }
  });
  builder.addCase(deleteAsset.fulfilled, (state, action) => {
    if (action.payload) {
      const promptId = action.payload.promptId;
      const assetId = action.payload.id;
      if (promptId) {
        if (state.promptMap[promptId]) {
          state.promptMap[promptId].assets = state.promptMap[promptId].assets.filter(asset => asset.id !== assetId);
        }
        Object.keys(state.promptResults).forEach(key => {
          const prompt = state.promptResults[key].find(prompt => prompt.id === promptId);
          if (prompt) {
            prompt.assets = prompt.assets.filter(asset => asset.id !== assetId);
          }
        });
      }
    }
  });
};
