import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { trackEvent } from '@analytics/trackEvent';
import { AnalyticsEvents } from './AnalyticsEvents';

type eventMapPath = '/explore' | '/create' | '/feed' | '/account' | '/theme' | '/prompt' | '/profile';

const eventMap: Record<eventMapPath, AnalyticsEvents> = {
  '/explore': AnalyticsEvents.exploreView,
  '/create': AnalyticsEvents.createView,
  '/feed': AnalyticsEvents.feedView,
  '/account': AnalyticsEvents.profileSettingsView,
  '/theme': AnalyticsEvents.themeView,
  '/prompt': AnalyticsEvents.promptView,
  '/profile': AnalyticsEvents.profileView
};

export function useTrackPageView() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      Object.keys(eventMap).forEach(path => {
        if (url.startsWith(path)) {
          trackEvent(eventMap[path as eventMapPath], { page: url });
        }
      });
    };

    handleRouteChange(router.asPath); // Track initial page view

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
}
