import type { AppProps } from 'next/app';
import { CookiesProvider } from 'react-cookie';
import { SessionProvider } from 'next-auth/react';
import NextNProgress from 'nextjs-progressbar';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { Provider } from 'react-redux';
import { store } from '../store';
import '/styles/global.css';
import 'swiper/css';
import 'swiper/css/navigation';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { queryClient as defaultQueryClient } from '../_data/queryClient';
import Head from 'next/head';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { initAmplitude } from '@analytics/trackEvent';
import { AnalyticsProvider } from '@analytics/AnalyticsProvider';
import { usePreserveScroll } from '@hooks/usePreserveScroll';
import { ThemeProvider } from '@components/ThemeProvider';
import '@styles/reactGridStyles.css';
import { api } from '@lib/api';
import { useGlobalWebsocketListener } from '@hooks/globalWebsocketListener';
import { Toaster } from 'sonner';

function WebsocketProvider({ children }: { children: React.ReactNode }) {
  useGlobalWebsocketListener();

  return <>{children}</>;
}

function PromptHunt({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const [queryClient] = React.useState(defaultQueryClient);

  React.useEffect(() => {
    initAmplitude();
  }, []);

  usePreserveScroll();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <script async></script>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <SkeletonTheme baseColor="#1c1c1c" highlightColor="#222222" duration={3}>
              <SessionProvider session={session}>
                <AnalyticsProvider>
                  <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
                    <CookiesProvider>
                      <GoogleAnalytics trackPageViews />
                      <NextNProgress color="#29D" options={{ showSpinner: false }} />
                      <Head>
                        <meta
                          name="viewport"
                          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                        />
                      </Head>
                      <WebsocketProvider>
                        <Component {...pageProps} />
                        <Toaster />
                      </WebsocketProvider>
                    </CookiesProvider>
                  </ThemeProvider>
                </AnalyticsProvider>
              </SessionProvider>
            </SkeletonTheme>
          </HydrationBoundary>
        </ErrorBoundary>
      </QueryClientProvider>
    </Provider>
  );
}

export default api.withTRPC(PromptHunt);
