import { ThemeResult } from '@components/Create/themes';
import { ModelReturnType } from '@pages/api/model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AspectRatio, PhotoSize } from '../../types/photo';

export interface CartState {
  themes?: (ThemeResult & {
    workflowName?: string;
    photoSize?: {
      aspectRatio: AspectRatio;
      size: PhotoSize;
    };
  })[];
  model?: ModelReturnType;
}

const initialState: CartState = {};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addTheme: (state, action) => {
      state.themes = [...(state.themes || []), action.payload];
    },
    removeTheme: (state, action) => {
      state.themes = state.themes?.filter(theme => theme.id !== action.payload);
    },
    clearThemes: state => {
      state.themes = [];
    },
    setModel: (state, action) => {
      state.model = action.payload;
    },
    updateThemeWorkflow: (
      state,
      action: PayloadAction<{
        themeId: string;
        workflowName: string;
      }>
    ) => {
      // find theme in state
      const themeIndex = state.themes?.findIndex(theme => theme.id === action.payload.themeId);
      if (themeIndex === -1) return;
      if (themeIndex === undefined) return;
      if (state.themes?.[themeIndex]) {
        state.themes[themeIndex].workflowName = action.payload.workflowName;
      }
    },
    updateThemePhotoSize: (
      state,
      action: PayloadAction<{
        themeId: string;
        aspectRatio: AspectRatio;
        size: PhotoSize;
      }>
    ) => {
      const themeIndex = state.themes?.findIndex(theme => theme.id === action.payload.themeId);
      if (themeIndex === -1) return;
      if (themeIndex === undefined) return;
      if (state.themes?.[themeIndex]) {
        state.themes[themeIndex].photoSize = {
          aspectRatio: action.payload.aspectRatio,
          size: action.payload.size
        };
      } else {
        console.error('Theme not found');
      }
    }
  }
});

export const {
  addTheme: addThemeAction,
  removeTheme: removeThemeAction,
  clearThemes: clearThemesAction,
  setModel: setModelAction,
  updateThemeWorkflow: updateThemeWorkflowAction,
  updateThemePhotoSize: updateThemePhotoSizeAction
} = cartSlice.actions;

export default cartSlice.reducer;
