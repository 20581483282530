import { api } from '@lib/api';
import { supabase } from '@lib/supabase';
import { useEffect } from 'react';
import { useSession } from 'next-auth/react';

export const useGlobalWebsocketListener = () => {
  const apiUtils = api.useUtils();
  const { data: session } = useSession();
  const userId = session?.user?.id;

  useEffect(() => {
    if (!userId) return;

    const channel = supabase
      .channel('realtime:globalListener')
      // Model listeners
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'model',
          filter: `user_id=eq.${userId}`
        },
        () => {
          apiUtils.model.invalidate();
          apiUtils.albums.getAll.invalidate();
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'model',
          filter: `user_id=eq.${userId}`
        },
        () => {
          apiUtils.model.getUserModels.invalidate();
          apiUtils.albums.getAll.invalidate();
          apiUtils.albums.getQueuePosition.invalidate();
        }
      )
      // Album listeners
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'album',
          filter: `user_id=eq.${userId}`
        },
        () => {
          apiUtils.albums.getAll.invalidate();
          apiUtils.albums.getQueuePosition.invalidate();
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'album',
          filter: `user_id=eq.${userId}`
        },
        () => {
          apiUtils.albums.getAll.invalidate();
          apiUtils.albums.getQueuePosition.invalidate();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [userId, apiUtils]);

  return null;
};
