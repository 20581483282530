import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import { trackEvent } from '@analytics/trackEvent';
import { ControlNetItem, CustomModel } from '@store/create/types';
import { store } from '@store/index';
import { setQueuePosition } from '@store/create/reducer';

function getBaseUrl() {
  return typeof window === 'undefined' ? `${process.env.HOST}` : ``;
}

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export async function createPrompt(body: any) {
  const errorMessages = [
    'Whoops! Our pixel mixer blinked. Retry?',
    'Uh-oh! Art-bot hiccup. Another go?',
    'Oops! Image machine snoozed. Try again?',
    'Aw, snap! Short circuit in our magic box. Redo?',
    'Yikes! Our creation station blinked. Retry?',
    'Oh no! Connection slip-up with our art-kit. Redo?',
    'Bummer! Our digital easel stuttered. Try again?',
    'Darn! Image bot lost a beat. Another try?'
  ];
  const shuffledErrorMessages = shuffleArray(errorMessages);

  const headers: HeadersInit = { 'Content-Type': 'application/json' };

  const model = body.model as CustomModel;
  let create_endpoint = body.beta ? 'create_beta' : 'create';

  if (model === 'PhChroma3' || model === 'PhChroma2') {
    create_endpoint = 'create_edge';
  }

  if (body.beta) {
    create_endpoint = 'create_edge';
  }

  let type = !body.initImageData ? 'txt2img' : body.maskImageData ? 'inpaint' : 'img2img';
  if (body.controlNetItems) {
    const isControlNetRequest = (body.controlNetItems as ControlNetItem[]).some(item => item.model !== 'none');
    if (isControlNetRequest) {
      type = 'controlnet';
    }
  }

  trackEvent(AnalyticsEvents.createPrompt, {
    providerName: body.providerName,
    isPrivate: body.isPrivate,
    imageCount: body.outputCount,
    model: body.model,
    // totalMS,
    type: type,
    width: body.width,
    height: body.height,
    inferenceSteps: body.inferenceSteps
  });

  const startTime = Date.now();

  const prompt = await fetch(`${getBaseUrl()}/api/prompts/${create_endpoint}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  });

  if (create_endpoint !== 'create_edge') {
    const response = await prompt.json();
    return response;
  }

  if (!prompt.ok) {
    throw new Error(prompt.statusText);
  }

  if (prompt.status === 413) {
    throw new Error('Request too large.');
  } else if (prompt.status === 504) {
    throw new Error('Request timed out. Please try again.');
  }
  if (prompt.status !== 201) {
    throw new Error((await prompt.json())?.message);
  }

  // This data is a ReadableStream
  const data = prompt.body;
  if (!data) {
    throw new Error('No data received');
  }

  // https://web.dev/streams/#the-getreader-and-read-methods
  const reader = data.getReader();
  const decoder = new TextDecoder();
  let responseString = '';
  let done = false;

  const MAX_ITERATIONS = 10000;
  let iterations = 0;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);
    if (chunkValue === `start` || chunkValue === `processing`) {
      continue;
    }
    responseString += chunkValue;

    if (responseString.includes('queuepositionstart') && !responseString.includes('queuepositionend')) {
      continue;
    }

    if (responseString.includes('queuepositionstart') && responseString.includes('queuepositionend')) {
      //const queuePositionStart = responseString.indexOf('queuepositionstart');
      //const queuePositionEnd = responseString.indexOf('queuepositionend');
      const queuePosition = responseString.replaceAll('queuepositionstart', '').replaceAll('queuepositionend', '');
      responseString = '';
      console.log('queuePosition');
      console.log(queuePosition);
      console.log(responseString);
      try {
        const queuePositionJSON = JSON.parse(queuePosition);
        store.dispatch(setQueuePosition(queuePositionJSON));
      } catch (e) {
        console.log(e);
        console.log('queuePosition is not valid json');
      }
    }

    iterations++;
    if (iterations > MAX_ITERATIONS) {
      throw new Error('Error while reading response stream.');
    }
  }
  // If responseString is valid json, break the loop
  try {
    // Remove any start or processing from the being of the responseString one by one
    if (responseString.startsWith('start')) {
      responseString = responseString.replace(/start/g, '');
    }
    while (responseString.startsWith('processing')) {
      responseString = responseString.replace(/processing/g, '');
    }
    // Remove queuepositionstart and queuepositionend and text in between
    while (responseString.includes('queuepositionend')) {
      responseString = responseString.replaceAll('queuepositionend', '');
    }
    while (responseString.includes('queuepositionstart')) {
      responseString = responseString.replaceAll('queuepositionstart', '');
    }
    //TODO: I should think of something better then this
    while (!responseString.startsWith('{"post"') && responseString.length > 0) {
      responseString.slice(1);
    }

    JSON.parse(responseString);
  } catch (e) {
    console.log('responseString is not valid json');
    console.log(responseString);
  }

  // Remove any starts from the responseString
  responseString = responseString.replace(/start/g, '');
  const endTime = Date.now();
  const totalMS = endTime - startTime;
  trackEvent(AnalyticsEvents.createPromptDone, {
    providerName: body.providerName,
    isPrivate: body.isPrivate,
    imageCount: body.outputCount,
    model: body.model,
    totalMS,
    type,
    width: body.width,
    height: body.height,
    inferenceSteps: body.inferenceSteps
  });
  try {
    const feedItem = JSON.parse(responseString);

    return feedItem;
  } catch (e) {
    console.log('response string: ');
    console.log(responseString);
    throw new Error(shuffledErrorMessages.pop());
  }
}
