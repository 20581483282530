import { ThemeResult } from '@components/Create/themes';
import { CroppedImage } from '@components/Train/ImageUpload';
import { DEFAULT_TRAINING_STEPS } from '@lib/comfy/workflows';
import { createSlice } from '@reduxjs/toolkit';

// Available models "chroma" | "ad-ms-50"    RvMs50Ad50 Chr3+RvMs50Ad50-50
export const AVAILABLE_MODELS = [
  'chroma',
  'realistic_vision_v51.safetensors',
  'cyberrealistic_v42.safetensors',
  'realisticVisionV60B1_v60B1VAE.safetensors',
  'epicrealism_naturalSinRC1VAE.safetensors',
  'realisticVisionV60B1_v51HyperVAE.safetensors',
  'CyberRealistic_V4.2_FP16.safetensors',
  'CyberRealistic_V5_FP16.safetensors',
  'realisticVisionV60B1_v51VAE.safetensors'
];
export type AvailableModels =
  | 'chroma'
  | 'realistic_vision_v51.safetensors'
  | 'cyberrealistic_v42.safetensors'
  | 'realisticVisionV60B1_v60B1VAE.safetensors'
  | 'epicrealism_naturalSinRC1VAE.safetensors'
  | 'realisticVisionV60B1_v51HyperVAE.safetensors'
  | 'CyberRealistic_V4.2_FP16.safetensors'
  | 'CyberRealistic_V5_FP16.safetensors'
  | 'realisticVisionV60B1_v51VAE.safetensors';

export interface TrainingState {
  name: string;
  ethnicity: string;
  eyeColor: string;
  gender: string;
  photos: CroppedImage[];
  instance_prompt: string;
  class_prompt: string;
  steps: number;
  accountName?: string;
  apiToken?: string;
  themes: ThemeResult[];
  glasses: boolean;
  age?: number;
  bodyType?: string;
  selectedModel: AvailableModels;
  referenceImage: string;
  resolution: number;
  is_lora: boolean;
  base_model: string;
}

const initialState: TrainingState = {
  name: '',
  ethnicity: '',
  eyeColor: '',
  gender: '',
  photos: [],
  instance_prompt: '',
  class_prompt: '',
  steps: DEFAULT_TRAINING_STEPS,
  accountName: '',
  apiToken: '',
  glasses: false,
  themes: [],
  selectedModel: 'CyberRealistic_V4.2_FP16.safetensors',
  referenceImage: '',
  resolution: 512,
  is_lora: true,
  base_model: 'stabilityai/stable-diffusion-xl-base-1.0'
};

export const trainingSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    setEyeColor: (state, action) => {
      state.eyeColor = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setPhotos: (state, action) => {
      state.photos = action.payload;
    },
    appendPhotos: (state, action) => {
      state.photos = [...state.photos, ...action.payload];
      const successImages = state.photos.filter(image => image.status === 'success');
      localStorage.setItem('selectedImages', JSON.stringify(successImages));
    },
    removePhoto: (state, action) => {
      state.photos = state.photos.filter(photo => photo.id !== action.payload);
      const successImages = state.photos.filter(image => image.status === 'success');
      localStorage.setItem('selectedImages', JSON.stringify(successImages));
    },
    setInstancePrompt: (state, action) => {
      state.instance_prompt = action.payload;
    },
    setClassPrompt: (state, action) => {
      state.class_prompt = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setAccountName: (state, action) => {
      state.accountName = action.payload;
    },
    setApiToken: (state, action) => {
      state.apiToken = action.payload;
    },
    setThemes: (state, action) => {
      state.themes = action.payload;
    },
    setGlasses: (state, action) => {
      state.glasses = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setBodyType: (state, action) => {
      state.bodyType = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setReferenceImage: (state, action) => {
      state.referenceImage = action.payload;
    },
    setResolution: (state, action) => {
      state.resolution = action.payload;
    },
    setIsLora: (state, action) => {
      state.is_lora = action.payload;
    },
    setBaseModel: (state, action) => {
      state.base_model = action.payload;
    }
  }
});

export const {
  setReferenceImage,
  setGlasses,
  setName,
  setEthnicity,
  setEyeColor,
  setGender,
  setPhotos,
  setInstancePrompt,
  setClassPrompt,
  setSteps,
  setAccountName,
  setApiToken,
  setThemes,
  appendPhotos,
  removePhoto,
  setAge,
  setBodyType,
  setSelectedModel,
  setResolution,
  setIsLora,
  setBaseModel
} = trainingSlice.actions;

export default trainingSlice.reducer;
